//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import apiClientRequest from "@/common/lib/api";
import { mapActions, mapState, mapMutations } from "vuex";
import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
import errorMessage from "@/common/constants/error-messages";
import getEnv from "@/common/lib/utils/env";
export default {
  name: "ChangePassword",
  components: {
    LandingPagePopUp
  },
  data: () => ({
    passwordsValid: false,
    password: "",
    passwordConfirm: "",
    showPassword: false,
    showPasswordConfirm: false,
    recaptchaVerified: false,
    isPasswordsValid: false,
    errorMessage
  }),
  computed: {
    buttonDisabled() {
      return this.recaptchaVerified && this.isPasswordConfirmed;
    },
    isPasswordConfirmed() {
      if (!!this.password && this.password == this.passwordConfirm) {
        return true;
      }
      return false;
    },
    passwordRules() {
      return [val => !!val || errorMessage.REQUIRED, val => val && val.length >= 8 && /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(val) || errorMessage.PASSWORD(8, "UPPER/lowercase characters, number and special character in (!@#$%^&*)")];
    },
    passwordConfirmRule() {
      if (this.isPasswordConfirmed) {
        return true;
      }
      return "Passwords must match.";
    },
    ...mapState({
      substrateApi: state => state.substrate.api,
      isLoading: state => state.substrate.isLoadingWallet,
      wallet: state => state.substrate.wallet
    }),
    sitekey() {
      return getEnv("VUE_APP_RECAPTCHA_SITE_KEY");
    }
  },
  methods: {
    ...mapActions({
      registerMnemonic: "substrate/registerMnemonic"
    }),
    ...mapMutations({
      setIsLoading: "substrate/SET_LOADING_WALLET"
    }),
    previous() {
      this.$router.push({
        name: "forgot-password"
      });
    },
    async register() {
      try {
        const result = await this.registerMnemonic({
          mnemonic: this.$route.params.mnemonic,
          password: this.password
        });
        if (!result.success) {
          throw "Mnemonic registration failed!";
        }
        const accounts = Object.keys(window.localStorage).filter(v => /account:/.test(v));
        accounts.forEach(a => {
          const detail = JSON.parse(window.localStorage.getItem(a));
          if (detail.address !== this.wallet.address) {
            window.localStorage.removeItem(a);
          }
        });
        this.$router.push({
          name: "registration-successful",
          params: {
            flag: "changed"
          }
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onVerifyRecaptcha(response) {
      const result = await apiClientRequest.post("/recaptcha", {
        response
      });
      if (result.data.success) this.recaptchaVerified = true;
    }
  }
};